import React, { useRef, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import styled from 'styled-components';
import gsap from 'gsap';

const AboutSectionContainer = styled.section`
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
`;

const AboutGrid = styled.div`
  position: relative;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
`;

const AboutContent = styled(motion.div)`
  display: flex;
  backdrop-filter: blur(10px);
  flex-direction: column;
  justify-content: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3rem;
  color: var(--color-secondary);
  margin-bottom: 2rem;
  position: relative;
  
  span {
    color: var(--color-primary);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: var(--color-primary);
  }
`;

const AboutDescription = styled(motion.div)`
  font-size: 1.2rem;
  color: var(--color-secondary);
  opacity: 0.9;
  line-height: 1.7;
  
  p {
    margin-bottom: 1.5rem;
  }
  
  p:last-child {
    margin-bottom: 0;
  }
`;

const StatsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 4rem;
`;

const StatItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const StatNumber = styled.span`
  font-size: 4rem;
  font-family: var(--font-primary);
  color: var(--color-primary);
  line-height: 1;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.span`
  font-size: 1rem;
  font-family: var(--font-tertiary);
  color: var(--color-secondary);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
`;

const AboutImage = styled(motion.div)`
  width: 100%;
  height: 600px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    opacity: 0.3;
    z-index: 2;
    pointer-events: none;
  }
  
  @media (max-width: 768px) {
    height: 450px;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(15, 15, 15, 0.6), transparent);
  z-index: 1;
`;

const AccentBox = styled(motion.div)`
  position: absolute;
  width: 50%;
  height: 50%;
  border: 1px solid var(--color-primary);
  top: -30px;
  right: -30px;
  z-index: -1;
`;

const AboutSection = () => {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const statsRef = useRef<HTMLDivElement>(null);

  // Parallax effect for about image
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], ["0%", "20%"]);

  // Animations on scroll
  useEffect(() => {
    // Text animation
    gsap.fromTo(
      textRef.current,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top bottom-=100",
          toggleActions: "play none none none",
        },
      }
    );

    // Image animation
    gsap.fromTo(
      imageRef.current,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.3,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top bottom-=100",
          toggleActions: "play none none none",
        },
      }
    );

    // Stats animation
    if (statsRef.current) {
      const statItems = statsRef.current.querySelectorAll('.stat-item');

      gsap.fromTo(
        statItems,
        {
          y: 30,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: statsRef.current,
            start: "top bottom-=100",
            toggleActions: "play none none none",
          },
        }
      );

      // Number counter animation
      statItems.forEach((item) => {
        const numElement = item.querySelector('.stat-number');
        let finalValue = 0;

        if (numElement) {
          const value = numElement.getAttribute('data-value');
          finalValue = value ? parseInt(value) : 0;
        }

        gsap.fromTo(
          numElement,
          { innerText: 0 },
          {
            innerText: finalValue,
            duration: 2,
            ease: "power2.out",
            snap: { innerText: 1 },
            scrollTrigger: {
              trigger: statsRef.current,
              start: "top bottom-=100",
              toggleActions: "play none none none",
            },
          }
        );
      });
    }
  }, []);

  return (
    <AboutSectionContainer ref={sectionRef} id="about">
      <AboutGrid>
        <AboutContent ref={textRef}>
          <SectionTitle>
            About <span>Me</span>
          </SectionTitle>

          <AboutDescription>
            <p>
              I am a passionate web developer specializing in crafting
              elegant digital experiences for discerning clients. With a keen eye for detail and
              a commitment to excellence, I transform visions into stunning realities.
            </p>
            <p>
              My approach combines cutting-edge technologies with timeless design principles,
              resulting in websites that are not only visually captivating but also functionally
              superior.
            </p>
            <p>
              Whether you're looking to establish a new digital presence or elevate your existing
              one, I bring the expertise and dedication needed to create something truly exceptional.
            </p>
          </AboutDescription>

          <StatsContainer ref={statsRef}>
            <StatItem className="stat-item">
              <StatNumber className="stat-number" data-value="8">0</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem className="stat-item">
              <StatNumber className="stat-number" data-value="50">0</StatNumber>
              <StatLabel>Projects Completed</StatLabel>
            </StatItem>
          </StatsContainer>
        </AboutContent>

        <ImageContainer ref={imageRef}>
          <AboutImage style={{ y }}>
            <img src="/angeloron.jpg" alt="Portrait" />
            <ImageOverlay />
          </AboutImage>
          <AccentBox
            initial={{ opacity: 0, x: 30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          />
        </ImageContainer>
      </AboutGrid>
    </AboutSectionContainer>
  );
};

export default AboutSection;
