import { MouseEvent, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { cn } from '@/utils';

const Navigation = [
  { id: 'home', name: 'Home' },
  { id: 'about', name: 'About' },
  { id: 'projects', name: 'Projects' },
  { id: 'skills', name: 'Skills' },
  { id: 'contact', name: 'Contact' },
]

const NavLink = styled.a`
  position: relative;
  font-size: 1.1rem;
  color: var(--color-text);
  cursor: pointer;
  transition: color 0.3s ease;
  
  &:hover, &.active {
    color: var(--color-primary);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    transition: width 0.3s ease;
  }
  
  &:hover::after, &.active::after {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuIcon = styled.div<{ $isOpen: boolean }>`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 3px;
  position: relative;
  z-index: 900;
  
  @media (max-width: 768px) {
    display: block;
  }
  
  &::before, &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--color-primary);
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
  }
  
  &::before {
    top: 0;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(45deg)' : 'translateY(-8px)')};
  }
  
  &::after {
    bottom: 0;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-45deg)' : 'translateY(8px)')};
  }
`;

const MobileNavLink = styled(motion.a)`
  font-size: 2rem;
  color: var(--color-text);
  margin: 1.5rem 0;
  cursor: pointer;
  transition: color 0.3s ease;
  
  &:hover, &.active {
    color: var(--color-primary);
  }
`;

// Animation variants
const navbarVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.6, 0.05, 0.01, 0.9],
    }
  },
};

const mobileMenuVariants = {
  closed: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 0.5,
      ease: [0.6, 0.05, 0.01, 0.9],
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: [0.6, 0.05, 0.01, 0.9],
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
};

const navLinkVariants = {
  closed: {
    opacity: 0,
    y: 50,
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0.6, 0.05, 0.01, 0.9],
    },
  },
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [isManuallyScrolling, setIsManuallyScrolling] = useState(false);

  // Handle scroll effect and hash change
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }

      // Set flag to indicate user is manually scrolling
      setIsManuallyScrolling(true);

      // Clear previous timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // After scrolling stops, update the active section
      timeoutId = setTimeout(() => {
        setIsManuallyScrolling(false);

        // Only update hash when user has stopped scrolling for a moment
        // Update active section based on scroll position
        const sections = ['home', 'about', 'projects', 'skills', 'contact'];
        const scrollPosition = window.scrollY + 200;  // Add offset for better UX

        for (const section of sections) {
          const element = document.getElementById(section);
          if (element) {
            const offsetTop = element.offsetTop;
            const offsetBottom = offsetTop + element.offsetHeight;

            if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
              if (activeSection !== section) {
                setActiveSection(section);
                // Update URL hash as user scrolls without jumping
                // Use replaceState instead of pushState to avoid creating browser history entries
                window.history.replaceState(null, "", `#${section}`);
              }
              break;
            }
          }
        }
      }, 200); // Longer timeout for better user experience
    };

    window.addEventListener('scroll', handleScroll);

    // Handle hash change but only if it wasn't triggered by scroll
    const handleHashChange = () => {
      // Only process hash changes that aren't from scrolling
      if (!isManuallyScrolling) {
        const hash = window.location.hash.replace('#', '');
        if (hash && document.getElementById(hash)) {
          setActiveSection(hash);
        }
      }
    };

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Check for hash on initial page load
    const initialHash = window.location.hash.replace('#', '');
    if (initialHash && document.getElementById(initialHash)) {
      // On initial load, set active section but don't force scroll
      setActiveSection(initialHash);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('hashchange', handleHashChange);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [scrolled, activeSection, isManuallyScrolling]);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [activeSection]);

  // Lock body scroll when mobile menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const toggleMenu = () => setIsOpen(!isOpen);

  const scrollToSection = (sectionId: string, updateHash = true) => {
    // Block automatic scrolling when user is manually scrolling
    if (isManuallyScrolling) return;

    const element = document.getElementById(sectionId);
    if (element) {
      // This is a programmatic scroll, not a manual one
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
      setActiveSection(sectionId);

      // Update URL hash without jumping
      if (updateHash) {
        window.history.pushState(null, "", `#${sectionId}`);
      }
    }
  };

  const handleNavClick = (e: MouseEvent, sectionId: string) => {
    e.preventDefault();

    // This is intentional navigation, not scroll detection
    setIsManuallyScrolling(false);
    scrollToSection(sectionId);
  };

  return (
    <>
      <motion.nav
        className={cn("fixed top-0 left-0 w-full z-100 transition-padding duration-500 ease-in-out",
          scrolled ? 'py-2 backdrop-blur-sm bg-[rgba(15,15,15,0.8)] shadow-[0_10px_30px_-10px_rgba(0,0,0,0.3)]' : 'py-6'
        )
        }
        initial="hidden"
        animate="visible"
        variants={navbarVariants}
      >
        <div className="flex items-center justify-between w-[90%] max-w-[1400px] mx-auto">
          <motion.div
            className="cursor-pointer font-normal text-3xl text-[var(--color-primary)] tracking-wider"
            onClick={() => scrollToSection('home')}
          >
            ANGE<span className="font-light text-[var(--color-secondary)]">LORON</span>
          </motion.div>

          <div className="items-center gap-12 hidden md:flex">
            {Navigation.map((item) => (
              <NavLink
                key={item.id}
                onClick={(e) => handleNavClick(e, item.id)}
                className={activeSection === item.id ? 'active' : ''}
                href={`#${item.id}`}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <motion.button
            className="relative block md:hidden cursor-pointer z-102 border-0 bg-transparent h-10 w-10"
            onClick={toggleMenu}
          >
            <MobileMenuIcon $isOpen={isOpen} />
          </motion.button>
        </div>
      </motion.nav>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="flex flex-col items-center justify-center md:hidden fixed top-0 left-0 w-full h-full bg-[rgba(15,15,15,0.98)] backdrop-blur-[10px] z-99"
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenuVariants}
          >
            <motion.div className="flex flex-col items-center gap-8">
              {Navigation.map((item) => (
                <MobileNavLink
                  key={item.id}
                  onClick={(e) => handleNavClick(e, item.id)}
                  className={activeSection === item.id ? 'active' : ''}
                  variants={navLinkVariants}
                  href={`#${item.id}`}
                >
                  {item.name}
                </MobileNavLink>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
