import { motion } from "framer-motion";
import styled from "styled-components";

export default function SocialLinks() {

    const SocialLinks = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 3rem;
  `;

    const SocialLink = styled(motion.a)`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(212, 175, 55, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    font-size: 1.2rem;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-bg);
      transform: translateY(-5px);
    }
  `;

    const links = [
        { name: 'LinkedIn', url: 'https://www.linkedin.com/in/ange-loron/', icon: 'fab fa-linkedin-in' },
        { name: 'Medium', url: 'https://angeit.medium.com/', icon: 'fab fa-medium-m' },
        { name: 'Twitter', url: 'https://x.com/ange_loron', icon: 'fab fa-twitter' },
        { name: 'Resume', url: '/ange_loron_resume.pdf', icon: 'fas fa-file-pdf' }
    ]


    return (
        <SocialLinks>
            {links.map((link) => (
                <SocialLink
                    key={link.name}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className={`${link.icon}`}></i>
                </SocialLink>
            ))}
        </SocialLinks>
    );
}