import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../Navigation/Navbar';
import Footer from './Footer';
import CustomCursor from './CustomCursor';
import BackgroundEffect from './BackgroundEffect';
import Diamond from '../Diamond';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  return (
    <LayoutContainer>
      <CustomCursor />
      <BackgroundEffect />
      <Diamond />
      <Navbar />
      <Main>{children}</Main>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
