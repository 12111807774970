import { Canvas, useFrame } from '@react-three/fiber';
import { Float, Environment } from '@react-three/drei';
import { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';

const isMobile = window.innerWidth <= 768;


let DiamondContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 1;
`;

const SECTIONS = {
    HERO: 'hero',
    ABOUT: 'about',
    PROJECTS: 'projects',
    SKILLS: 'skills',
    FOOTER: 'footer'
};

interface PositionData {
    x: number;
    y: number;
    z: number;
    scale: number;
}

// Positions for each section
const POSITIONS: Record<string, PositionData> = {
    [SECTIONS.HERO]: { x: isMobile ? 1 : 3, y: isMobile ? 1 : 0, z: 0, scale: isMobile ? 0.4 : 1 },
    [SECTIONS.ABOUT]: { x: isMobile ? 2 : 0, y: 0, z: 0, scale: isMobile ? 1 : 1.5 },
    [SECTIONS.PROJECTS]: { x: isMobile ? 0 : 0, y: 0, z: 0, scale: isMobile ? 1 : 1.5 },
    [SECTIONS.SKILLS]: { x: isMobile ? 2 : -7, y: 0, z: 0, scale: isMobile ? 1 : 1.5 },
    [SECTIONS.FOOTER]: { x: isMobile ? 1 : -4, y: isMobile ? (-4 + (410 * (8 / window.innerHeight))) : -1.7, z: 0, scale: isMobile ? 0.2 : 0.7 }
};

function DiamondScene() {
    const [currentSection, setCurrentSection] = useState(SECTIONS.HERO);
    const [targetPosition, setTargetPosition] = useState<PositionData>(POSITIONS[SECTIONS.HERO]);
    const diamondRef = useRef<THREE.Group>(null);

    // Update current section based on scroll position
    useEffect(() => {
        const updateSection = () => {
            const scrollPos = window.scrollY;
            const windowHeight = window.innerHeight;

            const heroSection = document.getElementById('home');
            const aboutSection = document.getElementById('about');
            const projectsSection = document.getElementById('projects');
            const skillsSection = document.getElementById('skills');
            const footerSection = document.querySelector('footer');

            if (!heroSection || !aboutSection || !projectsSection || !skillsSection || !footerSection) return;

            const aboutTop = aboutSection.offsetTop;
            const projectsTop = projectsSection.offsetTop;
            const skillsTop = skillsSection.offsetTop;
            const footerTop = footerSection.offsetTop;

            // Determine current section
            if (scrollPos < aboutTop - windowHeight / 2) {
                setCurrentSection(SECTIONS.HERO);
            } else if (scrollPos < projectsTop - windowHeight / 2) {
                setCurrentSection(SECTIONS.ABOUT);
            } else if (scrollPos < skillsTop - windowHeight / 2) {
                setCurrentSection(SECTIONS.PROJECTS);
            } else if (scrollPos < footerTop - windowHeight / 2) {
                setCurrentSection(SECTIONS.SKILLS);
            } else {
                setCurrentSection(SECTIONS.FOOTER);
            }
        };

        // Initial check
        updateSection();

        // Update on scroll
        window.addEventListener('scroll', updateSection, { passive: true });
        return () => window.removeEventListener('scroll', updateSection);
    }, []);

    // Update target position based on current section
    useEffect(() => {
        setTargetPosition(POSITIONS[currentSection]);
    }, [currentSection]);

    // Animate diamond position and scale
    useFrame((state, delta) => {
        if (!diamondRef.current) return;

        // Smooth interpolation for position and scale
        diamondRef.current.position.x = THREE.MathUtils.lerp(
            diamondRef.current.position.x,
            targetPosition.x,
            delta * 2
        );

        diamondRef.current.position.y = THREE.MathUtils.lerp(
            diamondRef.current.position.y,
            targetPosition.y,
            delta * 2
        );

        diamondRef.current.scale.x = THREE.MathUtils.lerp(
            diamondRef.current.scale.x,
            targetPosition.scale,
            delta * 2
        );

        diamondRef.current.scale.y = THREE.MathUtils.lerp(
            diamondRef.current.scale.y,
            targetPosition.scale,
            delta * 2
        );

        diamondRef.current.scale.z = THREE.MathUtils.lerp(
            diamondRef.current.scale.z,
            targetPosition.scale,
            delta * 2
        );

        // Rotate based on scroll progress
        diamondRef.current.rotation.y += delta * 0.2;
    });

    return (
        <>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
            <group ref={diamondRef}>
                <Float
                    speed={1.5}
                    rotationIntensity={0.2}
                    floatIntensity={0.2}
                >
                    <mesh castShadow>
                        <octahedronGeometry args={[2, 0]} />
                        <meshPhysicalMaterial
                            color="#ffffff"
                            metalness={0.9}
                            roughness={0.1}
                            clearcoat={1}
                            clearcoatRoughness={0.1}
                            transmission={0.9}
                            ior={2.33}
                            thickness={0.5}
                        />
                    </mesh>
                </Float>
            </group>
            <Environment preset="studio" />
        </>
    );
}

export default function Diamond() {

    return (
        <DiamondContainer>
            <Canvas
                camera={{ position: [0, 0, 10], fov: 45 }}
                dpr={[1, 1.5]}
                gl={{
                    antialias: false,
                    alpha: true,
                    powerPreference: 'high-performance'
                }}
            >
                <DiamondScene />
            </Canvas>
        </DiamondContainer>
    );

}