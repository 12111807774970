import { useState, useRef, useEffect, FormEvent, ChangeEvent } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import gsap from 'gsap';
import SocialLinks from '../SocialLinks';

const ContactSectionContainer = styled.section`
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 30% 30%, rgba(212, 175, 55, 0.03) 0%, transparent 70%);
    pointer-events: none;
  }
`;

const SectionContent = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3rem;
  color: var(--color-secondary);
  margin-bottom: 1.5rem;
  
  span {
    color: var(--color-primary);
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--color-secondary);
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
`;

const ContactInfo = styled(motion.div)`
  display: flex;
  backdrop-filter: blur(10px);
  padding: 0 4rem;
  flex-direction: column;
  justify-content: center;
`;

const InfoTitle = styled.h3`
  font-size: 2rem;
  color: var(--color-secondary);
  margin-bottom: 2rem;
  font-weight: 400;
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  color: var(--color-secondary);
  opacity: 0.8;
  margin-bottom: 3rem;
  line-height: 1.7;
`;

const ContactForm = styled(motion.form)`
  background-color: rgba(15, 15, 15, 0.5);
  backdrop-filter: blur(10px);
  padding: 4rem;
  border-radius: 3px;
  border: 1px solid rgba(212, 175, 55, 0.1);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -30px;
    right: -30px;
    width: 60px;
    height: 60px;
    border-top: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);
    opacity: 0.5;
  }
  
  @media (max-width: 768px) {
    padding: 3rem 2rem;
  }
`;

const FormGroup = styled(motion.div)`
  margin-bottom: 2rem;
  position: relative;
`;

const FormLabel = styled.label<{ $isFocused: boolean }>`
  position: absolute;
  top: 0;
  left: 15px;
  color: var(--color-secondary);
  font-size: 1rem;
  opacity: 0.7;
  transform-origin: left;
  transform: ${({ $isFocused }) => ($isFocused ? 'translateY(-100%) scale(0.8)' : 'translateY(0) scale(1)')};
  transition: all 0.3s ease;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px 15px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(245, 245, 245, 0.2);
  color: var(--color-secondary);
  font-size: 1rem;
  font-family: var(--font-tertiary);
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-bottom-color: var(--color-primary);
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 12px 15px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(245, 245, 245, 0.2);
  color: var(--color-secondary);
  font-size: 1rem;
  font-family: var(--font-tertiary);
  resize: vertical;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-bottom-color: var(--color-primary);
  }
`;

const SubmitButton = styled(motion.button)`
  background: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 1rem 2.5rem;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: var(--font-tertiary);
  font-weight: 300;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--color-primary);
    z-index: -1;
    transition: width 0.4s ease;
  }
  
  &:hover {
    color: var(--color-bg);
    
    &:before {
      width: 100%;
    }
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    
    &:hover {
      color: var(--color-primary);
      
      &:before {
        width: 0;
      }
    }
  }
`;

const StatusMessage = styled(motion.div) <{ status: 'success' | 'error' | null }>`
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  border-radius: 3px;
  font-family: var(--font-tertiary);
  background-color: ${({ status }) =>
    status === 'success' ? 'rgba(46, 204, 113, 0.2)' :
      status === 'error' ? 'rgba(231, 76, 60, 0.2)' : 'transparent'
  };
  color: ${({ status }) =>
    status === 'success' ? '#2ecc71' :
      status === 'error' ? '#e74c3c' : 'var(--color-secondary)'
  };
`;

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8 }
  }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    }
  }
};

const ContactSection = () => {
  type FormData = {
    name: string;
    email: string;
    subject: string;
    message: string;
    [key: string]: string;
  };

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [statusMessage, setStatusMessage] = useState('');

  const formRef = useRef<HTMLFormElement>(null);
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const contactDetailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Animation for contact details
    if (contactDetailsRef.current) {
      const details = contactDetailsRef.current.querySelectorAll('.contact-detail');

      gsap.fromTo(
        details,
        {
          x: -30,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top bottom-=100",
            toggleActions: "play none none none",
          },
        }
      );
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFocus = (field: string) => {
    setFocusedField(field);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const isFieldFocusedOrFilled = (field: string) => {
    return focusedField === field || formData[field].trim() !== '';
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current) {
      try {
        setSubmitting(true);
        setStatus(null);

        if (formRef.current) {
          const result = await emailjs.sendForm(
            'default_service',
            'template_99tdlyb',
            formRef.current,
            'iwrsahIIMMNOvcD8L'
          );

          if (result.status === 200) {
            setStatus('success');
            setStatusMessage('Your message has been sent successfully!');

            // Reset form
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: ''
            });
          }
        } else {
          console.error('Form reference is null');
        }
      } catch (error) {
        setStatus('error');
        setStatusMessage('There was an error sending your message. Please try again.');
        console.error('Error sending email:', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <ContactSectionContainer ref={sectionRef} id="contact">
      <SectionContent>
        <SectionHeader>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Get in <span>Touch</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Have a project in mind or want to discuss potential collaborations?
            I'd love to hear from you.
          </SectionSubtitle>
        </SectionHeader>

        <ContactGrid>
          <ContactInfo ref={contactDetailsRef}>
            <InfoTitle>Let's create something extraordinary together</InfoTitle>
            <InfoText>
              Whether you're looking to create a stunning website, develop a web application,
              or need consultation on your digital presence, I'm here to help.
              Feel free to reach out through the form or directly via the contact details below.
            </InfoText>

            <SocialLinks />
          </ContactInfo>

          <ContactForm
            ref={formRef}
            onSubmit={handleSubmit}
            variants={fadeInUp}
            initial="initial"
            animate="animate"
          >
            <motion.div variants={staggerContainer} initial="initial" animate="animate">
              <FormGroup variants={fadeInUp}>
                <FormLabel $isFocused={isFieldFocusedOrFilled('name')}>Your Name</FormLabel>
                <FormInput
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  onFocus={() => handleFocus('name')}
                  onBlur={handleBlur}
                  required
                />
              </FormGroup>

              <FormGroup variants={fadeInUp}>
                <FormLabel $isFocused={isFieldFocusedOrFilled('email')}>Your Email</FormLabel>
                <FormInput
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={() => handleFocus('email')}
                  onBlur={handleBlur}
                  required
                />
              </FormGroup>

              <FormGroup variants={fadeInUp}>
                <FormLabel $isFocused={isFieldFocusedOrFilled('message')}>Your Message</FormLabel>
                <FormTextarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  onFocus={() => handleFocus('message')}
                  onBlur={handleBlur}
                  required
                />
              </FormGroup>

              <SubmitButton
                type="submit"
                variants={fadeInUp}
                disabled={submitting}
                whileTap={{ scale: 0.95 }}
              >
                {submitting ? 'Sending...' : 'Send Message'}
              </SubmitButton>

              {status && (
                <StatusMessage
                  status={status}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {statusMessage}
                </StatusMessage>
              )}
            </motion.div>
          </ContactForm>
        </ContactGrid>
      </SectionContent>
    </ContactSectionContainer>
  );
};

export default ContactSection;
