import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Layout from './components/common/Layout';
import HeroSection from './components/Home/HeroSection';
import AboutSection from './components/About/AboutSection';
import ProjectsSection from './components/Projects/ProjectsSection';
import SkillsSection from './components/Skills/SkillsSection';
import ContactSection from './components/Contact/ContactSection';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Layout>
        <HeroSection />
        <AboutSection />
        <ProjectsSection />
        <SkillsSection />
        <ContactSection />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
