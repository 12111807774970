import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import gsap from 'gsap';

const CursorWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: var(--color-primary);
    border-radius: 50%;
    transition: all 0.2s ease-out;
  }

  &.hover {
    &::before {
      transform: translate(-50%, -50%) scale(4.5);
      background-color: white;
      opacity: 0.5;
    }
  }
`;

const OuterCursor = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  pointer-events: none;
  z-index: 9998;
  opacity: 0.6;
`;

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const outerCursorRef = useRef(null);
  
  useEffect(() => {
    if (cursorRef.current && outerCursorRef.current) {
      const cursor = cursorRef.current;
      const outerCursor = outerCursorRef.current;
      
      const moveCursor = (e) => {
        gsap.to(cursor, {
          x: e.clientX,
          y: e.clientY,
          duration: 0.1,
          ease: 'power2.out',
        });
        
        gsap.to(outerCursor, {
          x: e.clientX,
          y: e.clientY,
          duration: 0.5,
          ease: 'power2.out',
        });
      };
      
      const handleMouseOver = (e) => {
        if (e.target.tagName.toLowerCase() === 'a' || 
            e.target.tagName.toLowerCase() === 'button' ||
            e.target.classList.contains('hover-trigger')) {
          cursor.classList.add('hover');
          gsap.to(outerCursor, {
            scale: 1.5,
            opacity: 0.2,
            duration: 0.3,
          });
        }
      };
      
      const handleMouseOut = () => {
        cursor.classList.remove('hover');
        gsap.to(outerCursor, {
          scale: 1,
          opacity: 0.6,
          duration: 0.3,
        });
      };
      
      document.addEventListener('mousemove', moveCursor);
      document.addEventListener('mouseover', handleMouseOver);
      document.addEventListener('mouseout', handleMouseOut);
      
      return () => {
        document.removeEventListener('mousemove', moveCursor);
        document.removeEventListener('mouseover', handleMouseOver);
        document.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);
  
  return (
    <>
      <CursorWrapper ref={cursorRef} />
      <OuterCursor ref={outerCursorRef} />
    </>
  );
};

export default CustomCursor;
