import React, { useRef } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as THREE from 'three';
import styled from 'styled-components';

// Generate random points in 3D space
const generatePoints = (count, radius) => {
  const points = new Float32Array(count * 3);
  const colors = new Float32Array(count * 3);
  const color = new THREE.Color();
  
  for (let i = 0; i < count; i++) {
    // Create points in a sphere
    const theta = Math.random() * Math.PI * 2;
    const phi = Math.acos(2 * Math.random() - 1);
    const r = Math.random() * radius;
    
    const x = r * Math.sin(phi) * Math.cos(theta);
    const y = r * Math.sin(phi) * Math.sin(theta);
    const z = r * Math.cos(phi);
    
    points[i * 3] = x;
    points[i * 3 + 1] = y;
    points[i * 3 + 2] = z;
    
    // Give points a slight gold tint
    const intensity = 0.1 + Math.random() * 0.2;
    color.setHSL(0.12, 0.8, intensity); // Gold hue in HSL
    
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
  }
  
  return { positions: points, colors };
};

const ParticleSystem = ({ count = 2000, radius = 10 }) => {
  const { positions, colors } = React.useMemo(() => generatePoints(count, radius), [count, radius]);
  const pointsRef = useRef();
  const { mouse, viewport } = useThree();
  
  useFrame((state) => {
    if (pointsRef.current) {
      // Rotate the points slowly
      pointsRef.current.rotation.x = state.clock.getElapsedTime() * 0.05;
      pointsRef.current.rotation.y = state.clock.getElapsedTime() * 0.03;
      
      // Subtle movement towards mouse
      pointsRef.current.position.x += (mouse.x * viewport.width / 50 - pointsRef.current.position.x) * 0.01;
      pointsRef.current.position.y += (mouse.y * viewport.height / 50 - pointsRef.current.position.y) * 0.01;
    }
  });
  
  return (
    <Points
      ref={pointsRef}
      positions={positions}
      colors={colors}
      stride={3}
    >
      <PointMaterial
        transparent
        vertexColors
        size={0.07}
        sizeAttenuation={true}
        depthWrite={false}
        blending={THREE.AdditiveBlending}
      />
    </Points>
  );
};

const GlowingLine = ({ start, end, color = '#d4af37', thickness = 0.02 }) => {
  const lineRef = useRef();
  
  useFrame((state) => {
    if (lineRef.current) {
      // Pulsate effect
      const pulse = Math.sin(state.clock.getElapsedTime() * 2) * 0.2 + 0.8;
      lineRef.current.material.opacity = pulse * 0.8;
      lineRef.current.material.linewidth = thickness * pulse;
    }
  });
  
  const points = [
    new THREE.Vector3(...start),
    new THREE.Vector3(...end)
  ];
  
  const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
  
  return (
    <line ref={lineRef} geometry={lineGeometry}>
      <lineBasicMaterial attach="material" color={color} opacity={0.8} transparent />
    </line>
  );
};

// Create a few decorative lines
const DecorativeLines = () => {
  const lines = [
    { start: [-5, -5, -5], end: [5, 5, 5] },
    { start: [5, -5, -5], end: [-5, 5, 5] },
    { start: [-5, 5, -5], end: [5, -5, 5] },
    { start: [5, 5, -5], end: [-5, -5, 5] },
  ];
  
  return (
    <>
      {lines.map((line, index) => (
        <GlowingLine key={index} start={line.start} end={line.end} />
      ))}
    </>
  );
};

const CanvasContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
  background: radial-gradient(circle at center, #121212 0%, #070707 100%);
`;

const BackgroundEffect = () => {
  return (
    <CanvasContainer>
      <Canvas camera={{ position: [0, 0, 5], fov: 60 }}>
        <ambientLight intensity={0.2} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={0.5} />
        <ParticleSystem />
        <DecorativeLines />
      </Canvas>
    </CanvasContainer>
  );
};

export default BackgroundEffect;
