import { gsap } from 'gsap';

// Page transition animations
export const pageTransition = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, 0.05, 0.01, 0.9],
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.6,
      ease: [0.6, 0.05, 0.01, 0.9],
    },
  },
};

// Staggered text reveal animation
export const textReveal = (delay = 0) => ({
  initial: { y: 120, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, 0.05, 0.01, 0.9],
      delay,
    },
  },
});

// Image reveal animation
export const imageReveal = (delay = 0) => ({
  initial: { scale: 1.2, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: [0.6, 0.05, 0.01, 0.9],
      delay,
    },
  },
});

// Button hover animation with GSAP
export const buttonHoverAnimation = (element: HTMLElement) => {
  const timeline = gsap.timeline({ paused: true });
  
  timeline.to(element.querySelector('.btn-bg'), {
    width: '100%',
    duration: 0.4,
    ease: 'power3.out',
  });
  
  return timeline;
};

// Parallax effect for scrolling elements
export const parallaxScroll = (element: HTMLElement, scrollY: number, strength: number = 0.1) => {
  gsap.to(element, {
    y: scrollY * strength,
    duration: 0.8,
    ease: 'power1.out',
  });
};

// Reveal sections on scroll
export const revealSection = (element: HTMLElement, delay = 0) => {
  gsap.fromTo(
    element,
    {
      opacity: 0,
      y: 50,
    },
    {
      opacity: 1,
      y: 0,
      duration: 1,
      delay,
      ease: 'power3.out',
    }
  );
};

// Split text into lines and animate each line
export const splitTextAnimation = (element: HTMLElement, delay = 0) => {
  const text = element.textContent;
  const textValue = text ?? '';
  const words = textValue.split(' ');
  
  // Clear the element
  element.innerHTML = '';
  
  // Create wrapped spans for each word
  words.forEach((word, index) => {
    const span = document.createElement('span');
    span.textContent = word + ' ';
    span.style.display = 'inline-block';
    span.style.opacity = '0';
    element.appendChild(span);

    gsap.to(span, {
      opacity: 1,
      y: 0,
      duration: 0.7,
      delay: delay + (index * 0.05),
      ease: 'power3.out',
    });
  });
};
