import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { textReveal } from '../../utils/animations';

const Title = styled(motion.h1)`
  font-size: 6.5rem;
  line-height: 1;
  color: var(--color-secondary);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  font-weight: 400;
  overflow: hidden;
  
  .highlight {
    color: var(--color-primary);
    font-weight: 400;
  }
  
  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.8rem;
  color: var(--color-secondary);
  margin-bottom: 3rem;
  max-width: 600px;
  font-weight: 300;
  overflow: hidden;
  font-family: var(--font-secondary);
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ExploreButton = styled(motion.button)`
  background: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 1rem 2.5rem;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: var(--font-tertiary);
  font-weight: 300;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  pointer-events: auto;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--color-primary);
    z-index: -1;
    transition: width 0.4s ease;
  }
  
  &:hover {
    color: var(--color-bg);
    
    &:before {
      width: 100%;
    }
  }
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  p {
    font-size: 0.8rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: var(--color-secondary);
    opacity: 0.7;
    font-family: var(--font-tertiary);
  }
  
  .arrow {
    width: 20px;
    height: 30px;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 4px;
      height: 4px;
      background-color: var(--color-primary);
      border-radius: 50%;
      animation: scrollDown 1.5s infinite;
    }
  }
  
  @keyframes scrollDown {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 15px);
      opacity: 0;
    }
  }
`;

const HeroSection = () => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Parallax effect on scroll
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const titleElement = document.querySelector('.hero-title');
      const subtitleElement = document.querySelector('.hero-subtitle');

      if (titleElement && subtitleElement) {
        gsap.to(titleElement, {
          y: scrollY * 0.2,
          duration: 0.8,
          ease: 'power1.out',
        });

        gsap.to(subtitleElement, {
          y: scrollY * 0.1,
          duration: 0.8,
          ease: 'power1.out',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Button hover animation
    if (buttonRef.current) {
      const button = buttonRef.current as HTMLButtonElement;

      const handleMouseEnter = () => {
        gsap.to(button, {
          scale: 1.05,
          duration: 0.3,
          ease: 'power2.out',
        });
      };

      const handleMouseLeave = () => {
        gsap.to(button, {
          scale: 1,
          duration: 0.3,
          ease: 'power2.out',
        });
      };

      if (button) {
        button.addEventListener('mouseenter', handleMouseEnter);
        button.addEventListener('mouseleave', handleMouseLeave);
      }

      return () => {
        if (button) {
          button.removeEventListener('mouseenter', handleMouseEnter);
          button.removeEventListener('mouseleave', handleMouseLeave);
        }
      };
    }
  }, []);

  const handleExplore = () => {
    const projectsSection = document.getElementById('projects');
    if (projectsSection) {
      window.scrollTo({
        top: projectsSection.offsetTop,
        behavior: 'smooth',
      });
      window.history.pushState(null, "", '#projects');
    }
  };

  return (
    <section id="home" ref={containerRef} className="w-full h-screen flex items-center relative overflow-hidden">
      <div className="w-[90%] max-w-1400px mx-auto pointer-events-none">
        <Title
          className="hero-title"
          initial="initial"
          animate="animate"
          variants={textReveal(0.2)}
        >
          Crafting <span className="highlight">Elegant</span> <br />
          Digital Experiences
        </Title>

        <Subtitle
          className="hero-subtitle"
          initial="initial"
          animate="animate"
          variants={textReveal(0.4)}
        >
          Web development and design that elevates your brand to new heights
        </Subtitle>

        <ExploreButton
          ref={buttonRef}
          onClick={handleExplore}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.6 }}
          whileTap={{ scale: 0.95 }}
        >
          Explore Work
        </ExploreButton>
      </div>
      <ScrollIndicator
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2, duration: 0.8 }}
      >
        <p>Scroll</p>
        <div className="arrow" />
      </ScrollIndicator>
    </section>
  );
};

export default HeroSection;
