import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import gsap from 'gsap';

const ProjectsSectionContainer = styled.section`
  padding: 10rem 0;
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    pointer-events: none;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3rem;
  color: var(--color-secondary);
  margin-bottom: 5rem;
  text-align: center;
  
  span {
    color: var(--color-primary);
  }
`;

const ProjectsContainer = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
`;

const ProjectItem = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 15rem;
  position: relative;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
    
    .project-content {
      margin-left: 0;
      margin-right: 5%;
    }
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 10rem;
    
    &:nth-child(even) {
      flex-direction: column;
      
      .project-content {
        margin-right: 0;
      }
    }
  }
`;

const ProjectImageWrapper = styled(motion.div)`
  width: 55%;
  height: 500px;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    opacity: 0.3;
    z-index: 2;
    pointer-events: none;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
    height: 400px;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const ProjectImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease-out;
`;

const ProjectContent = styled(motion.div)`
  width: 40%;
  margin-left: 5%;
  z-index: 3;
  
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }
`;

const ProjectNumber = styled.span`
  font-family: var(--font-tertiary);
  font-size: 1rem;
  font-weight: 300;
  color: var(--color-primary);
  letter-spacing: 0.1rem;
  display: block;
  margin-bottom: 1rem;
  opacity: 0.8;
`;

const ProjectTitle = styled(motion.h3)`
  font-size: 2.5rem;
  color: var(--color-secondary);
  margin-bottom: 1.5rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: var(--color-primary);
  }
`;

const ProjectDescription = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--color-secondary);
  opacity: 0.9;
  line-height: 1.7;
  margin-bottom: 2rem;
`;

const ProjectTags = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const ProjectTag = styled(motion.span)`
  font-family: var(--font-tertiary);
  font-size: 0.8rem;
  color: var(--color-bg);
  background-color: var(--color-primary);
  padding: 0.5rem 1rem;
  border-radius: 2px;
  letter-spacing: 0.05rem;
  opacity: 0.9;
`;

const ProjectLinks = styled(motion.div)`
  display: flex;
  gap: 2rem;
`;

const ProjectLink = styled(motion.a)`
  font-family: var(--font-tertiary);
  font-size: 0.9rem;
  color: var(--color-secondary);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  transition: all 0.3s ease;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--color-primary);
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: var(--color-primary);
    
    &::after {
      width: 100%;
    }
  }
`;

const projectsData = [

  {
    id: 1,
    title: "DKOTMI Landing Page",
    description: "A modern, responsive landing page for an IT consulting startup, featuring elegant animations and clear service presentation. Built with Next.js for optimal performance and SEO.",
    image: "https://res.cloudinary.com/angeloron/image/upload/v1732113825/dkotmi_landingpage.jpg",
    tags: ["Next.js", "Tailwind CSS", "Framer Motion"],
    liveLink: "https://dkotmi.com",
    codeLink: null
  },
  {
    id: 2,
    title: "Self-Service Checkout",
    description: "A custom-built self-service checkout system for canteens using RFID technology for user identification and payments. Features real-time inventory management, multi-location support, and offline capability.",
    image: "https://res.cloudinary.com/angeloron/image/upload/v1736076777/self_service_ahglaw.jpg",
    tags: ["Electron", "Next.js", "Material UI", "SQLite"],
    liveLink: "https://dkotmi.com/self-service-checkout",
    codeLink: null
  },
  {
    id: 3,
    title: "E-Commerce Platform",
    description: "An online store with a complete cart system, product catalog, and secure checkout experience. Built with React and Material UI, using Paylike.io as the payment processing system.",
    image: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/v1584109646/loronpresets.jpg",
    tags: ["React", "Material UI", "Paylike.io", "Firebase"],
    liveLink: "https://loronpresets-mygl3.ondigitalocean.app/",
    codeLink: "https://gitlab.com/angeloron/loronpresets"
  },
  {
    id: 4,
    title: "Real Estate Template",
    description: "An open-source Real Estate landing page template controlled by a config.json file, making it easy to reuse for different projects. Features responsive design and modern UI elements.",
    image: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/v1585299545/realestatestemplate.jpg",
    tags: ["React", "Material UI"],
    liveLink: "https://angeloron.gitlab.io/estates-landing-page/",
    codeLink: "https://gitlab.com/angeloron/estates-landing-page"
  },
  {
    id: 5,
    title: "React Payment Form",
    description: "An open-source project for implementing online payments with React, Material-UI, and Stripe. Features a clean, user-friendly interface for donation collection or e-commerce integration.",
    image: "https://github.com/angeloron/react-material-ui-stripe-payment-form/raw/master/preview.gif",
    tags: ["React", "Material UI", "Stripe API"],
    liveLink: "https://angeloron.gitlab.io/react-material-ui-stripe-payment-form/",
    codeLink: "https://github.com/angeloron/react-material-ui-stripe-payment-form"
  },
  {
    id: 6,
    title: "Janitorial CRM",
    description: "A comprehensive Customer Relationship Management platform for a janitorial services company with real-time task management, worker assignments, scheduling, and financial analytics. Built with a responsive design for field operations.",
    image: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/yl8whegexdevs44cqeyb",
    tags: ["Next.js", "Material UI", "Clerk Auth", "ApexCharts"],
    liveLink: "https://dkotmi.com/janitorial-crm",
    codeLink: null
  }
];

const ProjectsSection = () => {
  const sectionRef = useRef(null);
  const projectRefs = useRef([]);

  // Set up refs for each project
  useEffect(() => {
    projectRefs.current = projectRefs.current.slice(0, projectsData.length);
  }, []);

  // Animation for projects on scroll
  useEffect(() => {
    const projects = projectRefs.current;

    projects.forEach((project) => {
      gsap.fromTo(
        project.querySelector('.project-image-wrapper'),
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: project,
            start: 'top bottom-=100',
            toggleActions: 'play none none none',
          },
        }
      );

      gsap.fromTo(
        project.querySelector('.project-content'),
        {
          y: 70,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          delay: 0.3,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: project,
            start: 'top bottom-=100',
            toggleActions: 'play none none none',
          },
        }
      );
    });
  }, []);

  // Hover effect for project images
  const handleProjectHover = (index, isHovering) => {
    const project = projectRefs.current[index];
    if (project) {
      const image = project.querySelector('.project-image');

      gsap.to(image, {
        scale: isHovering ? 1.05 : 1,
        duration: 0.7,
        ease: 'power2.out',
      });
    }
  };

  return (
    <ProjectsSectionContainer ref={sectionRef} id="projects">
      <SectionTitle
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Featured <span>Projects</span>
      </SectionTitle>

      <ProjectsContainer>
        {projectsData.map((project, index) => (
          <ProjectItem
            key={project.id}
            ref={(el) => (projectRefs.current[index] = el)}
            onMouseEnter={() => handleProjectHover(index, true)}
            onMouseLeave={() => handleProjectHover(index, false)}
          >
            <ProjectImageWrapper className="project-image-wrapper">
              <ProjectImage
                className="project-image"
                src={project.image}
                alt={project.title}
              />
            </ProjectImageWrapper>

            <ProjectContent className="project-content">
              <ProjectNumber>0{project.id}</ProjectNumber>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>

              <ProjectTags>
                {project.tags.map((tag, tagIndex) => (
                  <ProjectTag key={tagIndex}>{tag}</ProjectTag>
                ))}
              </ProjectTags>

              <ProjectLinks>
                {project.liveLink && <ProjectLink href={project.liveLink} target="_blank" rel="noopener noreferrer">
                  <span>View Live</span> <i className="fas fa-arrow-right"></i>
                </ProjectLink>}
                {project.codeLink && <ProjectLink href={project.codeLink} target="_blank" rel="noopener noreferrer">
                  <span>Source Code</span> <i className="fab fa-github"></i>
                </ProjectLink>}
              </ProjectLinks>
            </ProjectContent>
          </ProjectItem>
        ))}
      </ProjectsContainer>
    </ProjectsSectionContainer>
  );
};

export default ProjectsSection;
