import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const SkillsSectionContainer = styled.section`
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 70% 70%, rgba(212, 175, 55, 0.05) 0%, transparent 70%);
    pointer-events: none;
  }
`;

const SectionContent = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3rem;
  color: var(--color-secondary);
  margin-bottom: 1.5rem;
  
  span {
    color: var(--color-primary);
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--color-secondary);
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SkillCategory = styled(motion.div)`
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -30px;
    left: -30px;
    width: 60px;
    height: 60px;
    border-top: 1px solid var(--color-primary);
    border-left: 1px solid var(--color-primary);
    opacity: 0.5;
  }
`;

const CategoryTitle = styled(motion.h3)`
  font-size: 1.8rem;
  color: var(--color-primary);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  
  span {
    margin-left: 1rem;
  }
`;

const SkillsList = styled(motion.ul)`
  list-style: none;
  padding: 0;
`;

const SkillItem = styled(motion.li)`
  margin-bottom: 2rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SkillName = styled.h4`
  font-size: 1.2rem;
  color: var(--color-secondary);
  margin-bottom: 0.8rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  
  span {
    color: var(--color-primary);
    font-size: 1rem;
  }
`;

const SkillBar = styled.div<{ $percentage: number }>`
  width: 100%;
  height: 2px;
  background-color: rgba(245, 245, 245, 0.1);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ $percentage }) => $percentage}%;
    background-color: var(--color-primary);
    transition: width 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  
  &.animate::before {
    width: ${({ $percentage }) => $percentage}%;
  }
`;

const TechLogos = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 8rem;
`;

const LogoItem = styled(motion.div)`
  opacity: 0.6;
  filter: grayscale(100%);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    opacity: 1;
    filter: grayscale(0%);
    transform: translateY(-5px);
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

// Skill categories data
const skillsData = [
  {
    category: "Frontend Development",
    icon: "🎨",
    skills: [
      { name: "React & React Native", percentage: 95 },
      { name: "JavaScript / TypeScript", percentage: 90 },
      { name: "HTML5 & CSS3", percentage: 98 },
    ]
  },
  {
    category: "Backend Development",
    icon: "⚙️",
    skills: [
      { name: "Node.js & Express", percentage: 78 },
      { name: "GraphQL & REST APIs", percentage: 70 },
      { name: "Database Design", percentage: 85 }
    ]
  },
  {
    category: "UI/UX Design",
    icon: "✨",
    skills: [
      { name: "Figma", percentage: 75 },
      { name: "User Experience", percentage: 85 },
      { name: "Prototyping", percentage: 88 },
    ]
  }
];

// Technology logos
const techLogos = [
  { name: "CSS3", logo: "https://upload.wikimedia.org/wikipedia/commons/6/62/CSS3_logo.svg" },
  { name: "HTML5", logo: "https://upload.wikimedia.org/wikipedia/commons/3/38/HTML5_Badge.svg" },
  { name: "JavaScript", logo: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" },
  { name: "TypeScript", logo: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg" },
  { name: "React", logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" },
  { name: "Node.js", logo: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" },
  { name: "Figma", logo: "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg" },
];

const SkillsSection = () => {
  const sectionRef = useRef(null);
  const skillsRef = useRef<HTMLDivElement>(null);
  const skillBarsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Animation for skill bars
    const elements = skillBarsRef.current;

    const animateSkillBars = () => {
      elements.forEach((el) => {
        if (el) {
          ScrollTrigger.create({
            trigger: el,
            start: "top bottom-=100",
            onEnter: (self: ScrollTrigger) => self.trigger && self.trigger.classList.add("animate"),
            once: true
          });
        }
      });
    };

    if (elements.length > 0) {
      animateSkillBars();
    }

    // Animation for skill categories
    if (skillsRef.current) {
      const categories = skillsRef.current.querySelectorAll('.skill-category');

      gsap.fromTo(
        categories,
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: skillsRef.current,
            start: "top bottom-=100",
            toggleActions: "play none none none",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  // Reset skillBarsRef when skillsData changes
  useEffect(() => {
    skillBarsRef.current = skillBarsRef.current.slice(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsData]);

  return (
    <SkillsSectionContainer ref={sectionRef} id="skills">
      <SectionContent>
        <SectionHeader>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            My <span>Expertise</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            With years of experience in digital craftsmanship, I've honed my skills
            across various technologies to deliver exceptional results.
          </SectionSubtitle>
        </SectionHeader>

        <SkillsGrid ref={skillsRef}>
          {skillsData.map((category, index) => (
            <SkillCategory
              key={index}
              className="skill-category"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 * index }}
            >
              <CategoryTitle>
                {category.icon} <span>{category.category}</span>
              </CategoryTitle>

              <SkillsList>
                {category.skills.map((skill, skillIndex) => (
                  <SkillItem key={skillIndex}>
                    <SkillName>
                      {skill.name}
                      <span>{skill.percentage}%</span>
                    </SkillName>
                    <SkillBar
                      $percentage={skill.percentage}
                      ref={(el) => (skillBarsRef.current.push(el))}
                    />
                  </SkillItem>
                ))}
              </SkillsList>
            </SkillCategory>
          ))}
        </SkillsGrid>

        <TechLogos>
          {techLogos.map((tech, index) => (
            <LogoItem
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 0.6, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ opacity: 1, y: -5 }}
            >
              <img src={tech.logo} alt={tech.name} />
            </LogoItem>
          ))}
        </TechLogos>
      </SectionContent>
    </SkillsSectionContainer>
  );
};

export default SkillsSection;
