import { motion } from 'framer-motion';
import styled from 'styled-components';
import SocialLinks from '../SocialLinks';

const FooterContainer = styled.footer`
  padding-top: 3rem;
  background-color: #070707;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, transparent, var(--color-primary), transparent);
    opacity: 0.3;
    z-index: 0;
  }
`;

const FooterContent = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 5rem;
`;

const FooterInfo = styled.div`
  max-width: 400px;
`;

const FooterLogo = styled(motion.div)`
  font-family: var(--font-primary);
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-primary);
  letter-spacing: 0.1rem;
  margin-bottom: 1.5rem;
  
  span {
    color: var(--color-secondary);
    font-weight: 300;
  }
`;

const FooterDescription = styled.p`
  font-size: 1rem;
  color: var(--color-secondary);
  opacity: 0.7;
  line-height: 1.7;
  margin-bottom: 2rem;
`;

const FooterSocial = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(245, 245, 245, 0.1), transparent);
  margin-bottom: 2rem;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: var(--color-secondary);
  opacity: 0.6;
  font-family: var(--font-tertiary);
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <FooterTop>
          <FooterInfo>
            <FooterLogo>
              ANGE<span>LORON</span>
            </FooterLogo>
            <FooterDescription>
              Crafting elegant digital experiences that blend innovative technology with
              timeless design principles. Elevate your brand with web solutions.
            </FooterDescription>
            <FooterSocial>
              <SocialLinks />
            </FooterSocial>
          </FooterInfo>
        </FooterTop>
        <Divider />
        <FooterBottom>
          <Copyright>
            {currentYear} All Rights Reserved.
          </Copyright>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
