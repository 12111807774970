import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Cormorant+Garamond:wght@300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

  :root {
    --rgba-bg-primary: rgba(15, 15, 15, 0.97);
    --color-bg: #0f0f0f;
    --color-primary: #d4af37;
    --color-secondary: #f5f5f5;
    --color-text: #f5f5f5;
    --color-accent: #9370DB;
    --font-primary: 'Marcellus', serif;
    --font-secondary: 'Cormorant Garamond', serif;
    --font-tertiary: 'Montserrat', sans-serif;
    --transition-slow: 0.7s ease-in-out;
    --transition-medium: 0.5s ease-in-out;
    --transition-fast: 0.3s ease-in-out;
  }
 
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: var(--rgba-bg-primary);
    color: var(--color-text);
    font-family: var(--font-primary);
    font-size: 16px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) var(--color-bg);
  }

  section {
    z-index: 2;
  }

  body::-webkit-scrollbar {
    width: 6px;
  }

  body::-webkit-scrollbar-track {
    background: var(--color-bg);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 3px;
  }

  a {
    text-decoration: none;
    transition: color var(--transition-fast);
  }

  a:hover {
    color: var(--color-primary);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
    font-weight: 400;
    margin: 0 0 1rem 0;
    line-height: 1.2;
  }

  h1 {
    font-size: 4rem;
    letter-spacing: 0.1rem;
  }

  h2 {
    font-size: 3rem;
    letter-spacing: 0.08rem;
  }

  h3 {
    font-size: 2.5rem;
    letter-spacing: 0.06rem;
  }

  p {
    font-family: var(--font-secondary);
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  img, svg {
    max-width: 100%;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-family: var(--font-tertiary);
  }

  section {
    padding: 5rem 0;
  }

  .cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-primary);
    pointer-events: none;
    mix-blend-mode: difference;
    z-index: 9999;
    transform: translate(-50%, -50%);
    transition-property: width, height, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }

    section {
      padding: 3rem 0;
    }
  }
`;

export default GlobalStyles;
